import React from 'react';
import logo from './fxmatix.png';
import { Link } from "gatsby"

class Footer extends React.Component {

    render() {
        return (
            <>
            
                
            <div className="footer-green">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-9 col-xs-12">
                            <div className="row">
                                <div className="col-md-4 col-xs-12 logo-f">
                                <img className="web-header__logo" src={logo} alt="fxmatix" />
                    

                                </div>
                                <div className="col-md-8 col-xs-12 seo-text-f">
                                FXMatix.com is the leading portal for financial market news covering forex, commodities, and indices.

                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-12">
                            <ul className="snsc">
                                <li><a href="https://twitter.com/fxmatix" className="icoTwitter" title="Twitter">
                                    {/* <svg height="20px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-twitter fa-w-10 fa-3x"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" className=""></path></svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
                                </a></li>
                                <li><a href="https://www.youtube.com" className="icoLinkedin" title="Youtube">
                                    <svg height="20px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-youtube fa-w-18 fa-3x"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" className=""></path></svg></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default Footer;