import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"
import logo from './fxmatix.png';

class Header extends React.Component {

    render() {
        const rootPath = `${__PATH_PREFIX__}/`
        return (
            <nav className="nav12 navbar navbar-expand-lg navbar-light fixed-top">
                <div className="container">
                {this.props.tagName}
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to="/ringkasan-pasar">Ringkasan Pasar</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/nhan-dinh-bong-da">Nhận Định Bóng Đá</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/may-tinh-du-doan">Máy Tính Dự Đoán</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/cau-chuyen-bong-da">Câu Chuyện Bóng Đá</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/troll-bong-da">Troll Bóng Đá</Link>
                        </li> */}
                    </ul>
                </div>
                </div>
            </nav>
        )
    }
}

export default Header;